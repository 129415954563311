<template>
  <header
    class="bg-white dark:bg-slate-900 border-b border-slate-50 dark:border-slate-800"
  >
     <div v-if="successMessage" class="overlay">
        <div class="success-popup">
          {{ successMessage }}
        </div>
      </div>
    <div class="flex justify-between w-full py-2 px-4">
      <div class="flex items-center justify-center max-w-full min-w-[6.25rem]">
        <woot-sidemenu-icon />
        <h1
          class="m-0 text-xl text-slate-900 dark:text-slate-100 overflow-hidden whitespace-nowrap text-ellipsis my-0 mx-2"
        >
          {{ headerTitle }}
        </h1>
      </div>
      <div class="flex gap-2">
        <div
          class="max-w-[400px] min-w-[150px] flex items-center relative mx-2 search-wrap"
        >
          <div class="flex items-center absolute h-full left-2.5">
            <fluent-icon
              icon="search"
              class="h-5 leading-9 text-sm text-slate-700 dark:text-slate-200"
            />
          </div>
          <input
            type="text"
            :placeholder="$t('CONTACTS_PAGE.SEARCH_INPUT_PLACEHOLDER')"
            class="contact-search border-slate-100 dark:border-slate-600"
            :value="searchQuery"
            @keyup.enter="submitSearch"
            @input="inputSearch"
          />
          <woot-button
            :is-loading="false"
            class="clear"
            :class-names="searchButtonClass"
            @click="submitSearch"
          >
            {{ $t('CONTACTS_PAGE.SEARCH_BUTTON') }}
          </woot-button>
        </div>
        <div v-if="hasActiveSegments" class="flex gap-2">
          <woot-button
            class="clear"
            color-scheme="secondary"
            icon="edit"
            @click="onToggleEditSegmentsModal"
          >
            {{ $t('CONTACTS_PAGE.FILTER_CONTACTS_EDIT') }}
          </woot-button>
          <woot-button
            class="clear"
            color-scheme="alert"
            icon="delete"
            @click="onToggleDeleteSegmentsModal"
          >
            {{ $t('CONTACTS_PAGE.FILTER_CONTACTS_DELETE') }}
          </woot-button>
        </div>
        <div v-if="!hasActiveSegments" class="relative">
          <div
            v-if="hasAppliedFilters"
            class="absolute h-2 w-2 top-1 right-3 bg-slate-500 dark:bg-slate-500 rounded-full"
          />
          <woot-button
            class="clear"
            color-scheme="secondary"
            data-testid="create-new-contact"
            icon="filter"
            @click="toggleFilter"
          >
            {{ $t('CONTACTS_PAGE.FILTER_CONTACTS') }}
          </woot-button>
        </div>

        <woot-button
          v-if="hasAppliedFilters && !hasActiveSegments"
          class="clear"
          color-scheme="alert"
          variant="clear"
          icon="save"
          @click="onToggleSegmentsModal"
        >
          {{ $t('CONTACTS_PAGE.FILTER_CONTACTS_SAVE') }}
        </woot-button>
        <woot-button
          class="clear"
          color-scheme="success"
          icon="person-add"
          data-testid="create-new-contact"
          @click="toggleCreate"
        >
          {{ $t('CREATE_CONTACT.BUTTON_LABEL') }}
        </woot-button>

        <woot-button
          v-if="isAdmin"
          color-scheme="info"
          icon="upload"
          class="clear"
          @click="toggleImport"
        >
          {{ $t('IMPORT_CONTACTS.BUTTON_LABEL') }}
        </woot-button>

        <woot-button
          v-if="isAdmin"
          color-scheme="info"
          icon="download"
          class="clear"
          @click="submitExport"
        >
          {{ $t('EXPORT_CONTACTS.BUTTON_LABEL') }}
        </woot-button>
        <woot-button 
         v-if="isAdmin"
         @click="sendReport"
         >
        {{ $t('EXPORT_CONTACTS.REPORT') }}
        </woot-button>
      </div>
    </div>
    <div
      v-if="showSendReportModal"
      class="modal-overlay send_report_modal fixed inset-0 bg-black bg-opacity-50 z-50"
      style= "background-color: rgba(0, 0, 0, 0.3)!important;"
    >
  <div class="modal-content bg-white p-6 rounded-lg max-w-lg mx-auto my-24">
     <p @click="closeSendReportModal" class="close_button">
           x
     </p>
    <h2 class="text-xl font-semibold">{{ $t('EXPORT_CONTACTS.REPORT_MODAL_TITLE') }}</h2>
    <p>{{ $t('EXPORT_CONTACTS.REPORT_MODAL_DESCRIPTION') }}</p>
        <div class="mt-4">
          <div class="flex justify-between">
            <div class="flex flex-col">
              <label for="from-date" class="mb-1">{{ $t('EXPORT_CONTACTS.FROM_DATE') }}</label>
               <input
                  type="date"
                  id="from-date"
                  v-model="reportDateRange.from"
                  :max="maxFromDate"
                  class="border-slate-300 dark:border-slate-600 rounded-lg px-4 py-2"
                />
            </div>
            <div class="flex flex-col">
              <label for="to-date" class="mb-1">{{ $t('EXPORT_CONTACTS.TO_DATE') }}</label>
               <input
                  type="date"
                  id="to-date"
                  v-model="reportDateRange.to"
                  :min="minToDate"
                  :max="maxToDate"  
                  class="border-slate-300 dark:border-slate-600 rounded-lg px-4 py-2"
                />
            </div>
          </div>
        </div>
        <div class="flex justify-end mt-6">
          <woot-button
            class="ml-2"
            color-scheme="success"
            @click="confirmSendReport"
          >
            {{ $t('SEND_REPORT') }}
          </woot-button>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import adminMixin from 'dashboard/mixins/isAdmin';

export default {
  mixins: [adminMixin],
  props: {
    headerTitle: {
      type: String,
      default: '',
    },
    searchQuery: {
      type: String,
      default: '',
    },
    segmentsId: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return {
      showCreateModal: false,
      showImportModal: false,
       successMessage: '',
      showSuccessMessage: false,
      showSendReportModal: false,
        reportDateRange: {
        from: '',
        to: '',   
      },
    };
  },
  computed: {
    searchButtonClass() {
      return this.searchQuery !== '' ? 'show' : '';
    },
    ...mapGetters({
      getAppliedContactFilters: 'contacts/getAppliedContactFilters',
      accountId: 'getCurrentAccountId',
     currentUserId: 'getCurrentUserID', 
      currentUser: 'getCurrentUser',
    }),
    hasAppliedFilters() {
      return this.getAppliedContactFilters.length;
    },
    hasActiveSegments() {
      return this.segmentsId !== 0;
    },
    maxFromDate() {
      const today = new Date();
      return today.toISOString().split('T')[0];
    },
    minToDate() {
      const fromDate = this.reportDateRange.from ? new Date(this.reportDateRange.from) : new Date();
      fromDate.setDate(fromDate.getDate() + 1); 
      return fromDate.toISOString().split('T')[0]; 
    },
    maxToDate() {
      const today = new Date();
      return today.toISOString().split('T')[0]; 
    },
  },
  methods: {
    onToggleSegmentsModal() {
      this.$emit('on-toggle-save-filter');
    },
    onToggleEditSegmentsModal() {
      this.$emit('on-toggle-edit-filter');
    },
    onToggleDeleteSegmentsModal() {
      this.$emit('on-toggle-delete-filter');
    },
    toggleCreate() {
      this.$emit('on-toggle-create');
    },
    toggleFilter() {
      this.$emit('on-toggle-filter');
    },
    toggleImport() {
      this.$emit('on-toggle-import');
    },
    submitExport() {
      this.$emit('on-export-submit');
    },
    submitSearch() {
      this.$emit('on-search-submit');
    },
    sendReport(){
      this.showSendReportModal = true;
    },
      closeSuccessMessage() {
      this.showSuccessMessage = false;
      this.successMessage = '';
    },
     showSuccessNotification(message) {
        this.successMessage = message;
        this.showSuccessMessage = true;
        setTimeout(() => {
          this.showSuccessMessage = false;
        }, 2000);
      },
    closeSendReportModal() {
      this.showSendReportModal = false;
    },
    confirmSendReport() {
      if (!this.reportDateRange.from || !this.reportDateRange.to) return;

      axios.post(`api/v1/accounts/${this.accountId}/contacts/send_report`, {
          from_date: this.reportDateRange.from,
          to_date: this.reportDateRange.to,
          currentUserId: this.currentUserId
        }, {
          headers: {
            'Content-Type': 'application/json',
            'api-access-token': this.currentUser .access_token,
          }
        })
        .then((response) => { 
          this.showSuccessNotification(response.data.message); 
          this.closeSendReportModal();
        })
        .catch((error) => {
          this.showSuccessNotification("There was an error. Please try again.");
          this.showSuccessMessage = true;
        });
    },
    inputSearch(event) {
      this.$emit('on-input-search', event);
    },
  },
};
</script>

<style lang="scss" scoped>
.close_button{
  text-align:right;
  color:red;
  font-size:15px;
  cursor:pointer;
}
.search-wrap {
  .contact-search {
    @apply pl-9 pr-[3.75rem] text-sm w-full h-[2.375rem] m-0;
  }
  .send_report_modal{
      background-color: rgba(0, 0, 0, 0.3)!important;
  }
  .button {
    transition: transform 100ms linear;
    @apply ml-2 h-8 right-1 absolute py-0 px-2 opacity-0 -translate-x-px invisible;
  }

  .overlay {
    position: fixed;
    margin: auto;
    left: 50vw;
    width: 250px;
  }


  .close-button {
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
    font-size: 20px;
  }


  .button.show {
    @apply opacity-100 translate-x-0 visible;
  }
}
.success-popup {
  background-color: #44ce4b; /* Green color for success */
  color: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
  opacity: 0;
  animation: fadeInOut 2s forwards;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 400px;
  width: 100%;
  font-size: 16px;
}

.success-popup span {
  font-weight: bold;
}

.success-popup .close-button {
  cursor: pointer;
  font-size: 20px;
  color: white;
}
@keyframes fadeInOut {
  0% {
    opacity: 0;
    transform: translateX(-50%) translateY(-20px);
  }
  10% {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }
  90% {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-50%) translateY(20px);
  }
}

</style>
