<template>
  <div class="relative items-center p-4 bg-white dark:bg-slate-900">
    <div class="text-left rtl:text-right">
      <div class="flex justify-between flex-row">
        <thumbnail
          v-if="showAvatar"
          :src="contact.thumbnail"
          size="56px"
          :username="contact.name"
          :status="contact.availability_status"
        />
        <woot-button
          v-if="showCloseButton"
          :icon="closeIconName"
          class="clear secondary rtl:rotate-180"
          @click="onPanelToggle"
        />
      </div>

      <div class="mt-2 w-full">
        <div v-if="showAvatar" class="flex items-center mb-2 gap-1">
          <h3
            class="text-base text-slate-800 dark:text-slate-100 capitalize whitespace-normal my-0"
          >
            {{ contact.name }}
          </h3>
          <fluent-icon
            v-if="contact.created_at"
            v-tooltip="
              `${$t('CONTACT_PANEL.CREATED_AT_LABEL')} ${dynamicTime(
                contact.created_at
              )}`
            "
            icon="info"
            size="14"
            class="mt-0.5"
          />
          <a
            :href="contactProfileLink"
            class="fs-default"
            target="_blank"
            rel="noopener nofollow noreferrer"
          >
            <woot-button
              size="tiny"
              icon="open"
              variant="clear"
              color-scheme="secondary"
            />
          </a>
        </div>

        <p v-if="additionalAttributes.description" class="break-words">
          {{ additionalAttributes.description }}
        </p>
        <social-icons :social-profiles="socialProfiles" />
        <div class="mb-3">
          <contact-info-row
            :href="contact.email ? `mailto:${contact.email}` : ''"
            :value="contact.email"
            icon="mail"
            emoji="✉️"
            :title="$t('CONTACT_PANEL.EMAIL_ADDRESS')"
            show-copy
          />
          <contact-info-row
            :href="contact.phone_number ? `tel:${contact.phone_number}` : ''"
            :value="contact.phone_number"
            icon="call"
            emoji="📞"
            :title="$t('CONTACT_PANEL.PHONE_NUMBER')"
            show-copy
          />
          <contact-info-row
            v-if="contact.identifier"
            :value="contact.identifier"
            icon="contact-identify"
            emoji="🪪"
            :title="$t('CONTACT_PANEL.IDENTIFIER')"
          />
          <contact-info-row
            :value="additionalAttributes.company_name"
            icon="building-bank"
            emoji="🏢"
            :title="$t('CONTACT_PANEL.COMPANY')"
          />
          <contact-info-row
            v-if="location || additionalAttributes.location"
            :value="location || additionalAttributes.location"
            icon="map"
            emoji="🌍"
            :title="$t('CONTACT_PANEL.LOCATION')"
          />
          <contact-info-row
            :value="formattedChatDateTime"
            icon="clock"
            emoji="🏢"
            :title="$t('CONTACT_PANEL.DEMOTIME')"
          />
        </div>
      </div>
      <div class="flex items-center w-full mt-2 gap-2">
        <woot-button
          v-tooltip="$t('CONTACT_PANEL.NEW_MESSAGE')"
          title="$t('CONTACT_PANEL.NEW_MESSAGE')"
          icon="chat"
          size="small"
          @click="toggleConversationModal"
        />
        <woot-button
          v-tooltip="$t('EDIT_CONTACT.BUTTON_LABEL')"
          title="$t('EDIT_CONTACT.BUTTON_LABEL')"
          icon="edit"
          variant="smooth"
          size="small"
          @click="toggleEditModal"
        />
        <woot-button
          v-tooltip="$t('CONTACT_PANEL.MERGE_CONTACT')"
          title="$t('CONTACT_PANEL.MERGE_CONTACT')"
          icon="merge"
          variant="smooth"
          size="small"
          color-scheme="secondary"
          :disabled="uiFlags.isMerging"
          @click="openMergeModal"
        />
        <woot-button
          v-if="isAdmin"
          v-tooltip="$t('DELETE_CONTACT.BUTTON_LABEL')"
          title="$t('DELETE_CONTACT.BUTTON_LABEL')"
          icon="delete"
          variant="smooth"
          size="small"
          color-scheme="alert"
          :disabled="uiFlags.isDeleting"
          @click="toggleDeleteModal"
        />

        <woot-button
          :key="showCallButton"
          :title="$t(calltitle)"
          :icon="currentIcon"
          color-scheme="secondary"
          variant="smooth"
          size="small"
          @click="startupClient"
        />
        <woot-button
          class="reminder_icon"
          v-tooltip="$t('EDIT_CONTACT.SET_REMAINDER')"
          title="$t('EDIT_CONTACT.SET_REMAINDER')"
          icon="calendar-clock"
          color-scheme="secondary"
          size="small"
          @click="openRemainderModal"
        />

        <woot-button
          v-if="isAdmin"
          v-tooltip="$t('ADD_AGENT.BUTTON_LABEL')"
          :title="$t('ADD_AGENT.TITLE')"
          :icon="userIcon"
          color-scheme="secondary"
          variant="smooth"
          size="small"
          @click="toggleDropdown"
        />

        <draggable-file
          :show="showDragmodal"
          :formatted="formattedTime"
          :hangup="CallHangup"
          :selectedContact="selectedContact"
        />
      </div>
      <edit-contact
        v-if="showEditModal"
        :show="showEditModal"
        :contact="contact"
        @cancel="toggleEditModal"
      />
      <new-conversation
        v-if="contact.id"
        :show="showConversationModal"
        :contact="contact"
        @cancel="toggleConversationModal"
      />
      <contact-merge-modal
        v-if="showMergeModal"
        :primary-contact="contact"
        :show="showMergeModal"
        @close="toggleMergeModal"
      />
      <set-remainder-modal
        v-if="showRemainderModal"
        :primary-contact="contact"
        :show="showRemainderModal"
        :date-part="datePart"
        :time-without-z="timeWithoutZ"
        @close="toggleReminderModal"
        @success-message="successMessageData"
      />
    </div>
    <woot-delete-modal
      v-if="showDeleteModal"
      :show.sync="showDeleteModal"
      :on-close="closeDelete"
      :on-confirm="confirmDeletion"
      :title="$t('DELETE_CONTACT.CONFIRM.TITLE')"
      :message="$t('DELETE_CONTACT.CONFIRM.MESSAGE')"
      :message-value="confirmDeleteMessage"
      :confirm-text="$t('DELETE_CONTACT.CONFIRM.YES')"
      :reject-text="$t('DELETE_CONTACT.CONFIRM.NO')"
    />    
    <div v-if="isDropdownVisible" class="multiselect-wrap--small mt-2">
      <multiselect-dropdown
        :options="agentsList"
        :selected-item="assignedAgent"
        :multiselector-title="$t('AGENT_MGMT.MULTI_SELECTOR.TITLE.AGENT')"
        :multiselector-placeholder="$t('AGENT_MGMT.MULTI_SELECTOR.PLACEHOLDER')"
        :no-search-result="$t('AGENT_MGMT.MULTI_SELECTOR.SEARCH.NO_RESULTS.AGENT')"
        :input-placeholder="$t('AGENT_MGMT.MULTI_SELECTOR.SEARCH.PLACEHOLDER.AGENT')"
        @click="onClickAssignAgent" 
      />
    </div>

  </div>
</template>
<script>
import { mixin as clickaway } from 'vue-clickaway';
import timeMixin from 'dashboard/mixins/time';
import ContactInfoRow from './ContactInfoRow';
import Thumbnail from 'dashboard/components/widgets/Thumbnail.vue';
import SocialIcons from './SocialIcons';
import DraggableFile from '../../../../components/widgets/WootWriter/Draggable.vue';
import EditContact from './EditContact';
import NewConversation from './NewConversation';
import ContactMergeModal from 'dashboard/modules/contact/ContactMergeModal';
import SetRemainderModal from 'dashboard/modules/contact/SetRemainderModal';
import alertMixin from 'shared/mixins/alertMixin';
import adminMixin from '../../../../mixins/isAdmin';
import { mapGetters } from 'vuex';
import { getCountryFlag } from 'dashboard/helper/flag';
import MultiselectDropdown from 'shared/components/ui/MultiselectDropdown.vue';
let token;
let device;
let call;
export default {
  components: {
    ContactInfoRow,
    EditContact,
    Thumbnail,
    SocialIcons,
    NewConversation,
    ContactMergeModal,
    SetRemainderModal,
    DraggableFile,
    MultiselectDropdown,
  },
  mixins: [alertMixin, adminMixin, clickaway, timeMixin],
  props: {
    contact: {
      type: Object,
      default: () => ({}),
    },
    channelType: {
      type: String,
      default: '',
    },
    showAvatar: {
      type: Boolean,
      default: true,
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
    closeIconName: {
      type: String,
      default: 'chevron-right',
    },
  },
  data() {
    return {
      showEditModal: false,
      showConversationModal: false,
      showMergeModal: false,
      showRemainderModal: false,
      showDeleteModal: false,
      showDragmodal: false,
      showCallButton: true,
      calltitle: 'CONVERSATION.REPLYBOX.CALL',
      currentIcon: 'call-start',
      seconds: 0,
      minutes: 0,
      hours: 0,
      selectedContact: null,
      conversationId: '',
      datePart: '',
      timeWithoutZ: '',
      updatedDate: '',
      updatedTime: '',
      demo_time: null,
      conversationId: null,
      formatted: false,
      isDropdownVisible: false,
      agentsList: [],
      assignedAgent: null, 
      userIcon: 'user',
    };
  },
  watch: {
    formatted(newVal) {
      console.log('New Val', newVal);
      this.getConversationData();
    },
  },
  mounted() {
    this.getConversationData();
    this.fetchAgents(); 
  },
  computed: {
    formattedTime() {
      return (
        this.pad(this.hours) +
        ':' +
        this.pad(this.minutes) +
        ':' +
        this.pad(this.seconds)
      );
    },
    ...mapGetters({
      uiFlags: 'contacts/getUIFlags',
      accountId: 'getCurrentAccountId',
      baseUrl: 'getbaseUrl',
      currentUserId: 'getCurrentUserID',
      currentChat: 'getSelectedChat',
      current_userId: 'getCurrentUser',
    }),
    formattedChatDateTime() {
      this.formatted = !this.formatted;
      console.log(this.currentChat);
      let date = null;
      if (this.demo_time != null) {
        date = new Date(this.demo_time);
      } else if (this.currentChat.demo_time != null) {
        const dateTimeStr = this.currentChat.demo_time;
        date = new Date(dateTimeStr);
      }

      if (date === null || isNaN(date)) {
        this.datePart = '';
        this.timeWithoutZ = '';
        return 'Not Available';
      }
      const formattedDate = date.toLocaleDateString(navigator.language, {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
      });
      const formattedTime = date
        .toLocaleTimeString(navigator.language, {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: true,
        })
        .replace('am', 'AM')
        .replace('pm', 'PM'); // Ensuring AM/PM in uppercase
      this.datePart = formattedDate;
      this.timeWithoutZ = formattedTime;
      return `${formattedDate}, ${formattedTime}`;
    },

    currentContact() {
      return this.$store.getters['contacts/getContact'](
        this.currentChat.meta.sender.id
      );
    },
    contactProfileLink() {
      return `/app/accounts/${this.$route.params.accountId}/contacts/${this.contact.id}`;
    },
    additionalAttributes() {
      return this.contact.additional_attributes || {};
    },
    location() {
      const {
        country = '',
        city = '',
        country_code: countryCode,
      } = this.additionalAttributes;
      const cityAndCountry = [city, country].filter(item => !!item).join(', ');

      if (!cityAndCountry) {
        return '';
      }
      return this.findCountryFlag(countryCode, cityAndCountry);
    },
    socialProfiles() {
      const {
        social_profiles: socialProfiles,
        screen_name: twitterScreenName,
      } = this.additionalAttributes;

      return { twitter: twitterScreenName, ...(socialProfiles || {}) };
    },
    // Delete Modal
    confirmDeleteMessage() {
      return ` ${this.contact.name}?`;
    },
  },
  methods: {
    toggleMergeModal() {
      this.showMergeModal = !this.showMergeModal;
    },
    toggleReminderModal() {
      this.showRemainderModal = !this.showRemainderModal;
    },
    toggleEditModal() {
      this.showEditModal = !this.showEditModal;
    },
    onPanelToggle() {
      this.$emit('toggle-panel');
    },
    toggleConversationModal() {
      this.showConversationModal = !this.showConversationModal;
    },

    startTimer() {
      this.timerRunning = true;
      this.timer = setInterval(this.updateTimer, 1000);
    },
    stopTimer() {
      clearInterval(this.timer);
      this.timerRunning = false;
    },
    updateTimer() {
      this.seconds++;
      if (this.seconds === 60) {
        this.seconds = 0;
        this.minutes++;
        if (this.minutes === 60) {
          this.minutes = 0;
          this.hours++;
        }
      }
    },
    resetTimer() {
      this.stopTimer();
      this.selectedContact = null;
      this.seconds = 0;
      this.minutes = 0;
      this.hours = 0;
    },
    async CallHangup() {
      console.log('Call Disconnected');
      call.disconnect();
      this.$store.dispatch('contacts/toggleholdCallAvailableStatusFalse');
    },
    async updateUIAcceptedOutgoingCall() {
      this.startTimer();
    },

    async updateUIDisconnectedOutgoingCall() {
      alert('Call Ended');
      this.showDragmodal = false;
      this.resetTimer();
      console.log('::::::::::::Call has ended::::::::::');
    },
    async makeOutgoingCall() {
      try {
        const contact = this.contact;
        var params = {
          To: contact.phone_number,
          account_id: this.accountId,
          contact_id: contact.id,
          userId: this.currentUserId,
        };
        if (device) {
          console.log(`Attempting to call ${params.To} ...`);
          call = await device.connect({ params });
          call.on('accept', this.updateUIAcceptedOutgoingCall);
          call.on('disconnect', this.updateUIDisconnectedOutgoingCall);
          call.on('cancel', this.updateUIDisconnectedOutgoingCall);
        } else {
          console.log('Unable to make call.');
        }
      } catch (error) {
        console.error(
          'Error making outgoing call:-------------------------',
          error
        );
      }
    },

    async startupClient() {
      console.log('Requesting Access Token...');
      const contact = this.contact;
      this.selectedContact = contact;
      console.log('My COntact:::::', contact);
      let data; // Declare data variable outside the try block
      try {
        this.$store.dispatch('contacts/toggleholdCallAvailableStatusTrue');
        this.showDragmodal = !this.showDragmodal;
        this.getAudioDevices();
        console.log('Contact in yourMethod:', contact);
        console.log('User Id', this.currentUserId);
        // Remove redeclaration of data variable here
        const response = await axios.get(`${this.baseUrl}/twilio/token`, {
          params: {
            To: contact.phone_number,
            account_id: this.accountId,
            user_id: this.currentUserId,
          },
        });
        data = response.data;

        token = data.token;
        console.log('got token' + token);
        await this.intitializeDevice();
        this.makeOutgoingCall();
      } catch (err) {
        alert(data.message);
        this.showDragmodal = !this.showDragmodal;
        console.log(
          'An error occurred. See your browser console for more information.'
        );
      }
    },

    async getAudioDevices() {
      console.log('Inside getAudioDevices::::::::::::::::::::');
      await navigator.mediaDevices.getUserMedia({ audio: true });
    },
    addDeviceListeners(device) {
      device.on('registered', function () {
        console.log('Twilio.Device Ready to make and receive calls!');
      });
      device.on('error', function (error) {
        console.log('Twilio.Device Error: ' + error.message);
      });
    },
    async intitializeDevice() {
      console.log('Initializing device');
      device = new Twilio.Device(token, {
        logLevel: 1,
      });
      this.addDeviceListeners(device);
      device.register();
    },
    toggleDeleteModal() {
      this.showDeleteModal = !this.showDeleteModal;
    },
    confirmDeletion() {
      this.deleteContact(this.contact);
      this.closeDelete();
    },
    closeDelete() {
      this.showDeleteModal = false;
      this.showConversationModal = false;
      this.showEditModal = false;
    },
    findCountryFlag(countryCode, cityAndCountry) {
      try {
        const countryFlag = countryCode ? getCountryFlag(countryCode) : '🌎';
        return `${cityAndCountry} ${countryFlag}`;
      } catch (error) {
        return '';
      }
    },
    async deleteContact({ id }) {
      try {
        await this.$store.dispatch('contacts/delete', id);
        this.$emit('panel-close');
        this.showAlert(this.$t('DELETE_CONTACT.API.SUCCESS_MESSAGE'));
        if (this.$route.name !== 'contacts_dashboard') {
          this.$router.push({ name: 'contacts_dashboard' });
        }
      } catch (error) {
        this.showAlert(
          error.message
            ? error.message
            : this.$t('DELETE_CONTACT.API.ERROR_MESSAGE')
        );
      }
    },
    openMergeModal() {
      this.toggleMergeModal();
    },
    pad(value) {
      return value < 10 ? '0' + value : value;
    },
    openRemainderModal() {
      this.showRemainderModal = true;
    },
    successMessageData({
      message,
      conversationId,
      formattedDate,
      formattedTime,
      demo_time,
    }) {
      if (message) {
        this.conversationId = conversationId;
        this.updatedDate = formattedDate;
        this.updatedTime = formattedTime;
        this.demo_time = demo_time;
        this.getConversationData();
        this.showAlert(this.$t('DELETE_CONTACT.API.DEMO_MESSAGE'));
        this.formattedChatDateTime;
      }
    },
    async getConversationData() {
      const contactId = this.currentChat.meta?.sender?.id;
      let response;
      if (contactId) {
        response = await this.$store.dispatch(
          'contacts/getConversationData',
          contactId
        );
      }
      this.demo_time = response?.demo_time;
    },
    toggleDropdown() {
      this.isDropdownVisible = !this.isDropdownVisible;
    },
    async fetchAgents() {
      try {
        const response = await axios.get(`${this.baseUrl}/api/v1/accounts/${this.accountId}/agents`);
        this.agentsList = response.data.map(agent => {
          if (agent.id === this.current_userId.id) {
            return {
              ...agent,
              name: this.$t('CONVERSATION_SIDEBAR.SELF_ASSIGN'), 
              selfAssign: true
            };
          }
          return agent;
        });
      } catch (error) {
        console.error('Error fetching agents:', error);
      }
    },
    onClickAssignAgent(selectedAgent) {
      if (selectedAgent) {
        const agentId = selectedAgent.selfAssign ? this.current_userId.id : selectedAgent.id; // Check if self-assign
        const contactId = this.contact.id;
        if (contactId && agentId) {
          axios
            .post(`${this.baseUrl}/api/v1/accounts/${this.accountId}/contacts/${contactId}/assignments`, {
              agent_id: agentId,
            }, {
              headers: {
                'api-access-token': this.current_userId.access_token,
              },
            })
            .then(() => {
              this.showAlert('Agent successfully assigned');
              this.assignedAgent = selectedAgent;
              this.isDropdownVisible = false;
            })
            .catch((error) => {
              console.error('Error assigning agent:', error);
            });
        } else {
          console.error('Missing contact ID or agent ID');
        }
      }
    },
    findAssignedAgent() {
      // Logic to find and return the currently assigned agent, if applicable
      return this.agentsList.find(agent => agent.id === this.currentChat?.assignee_id) || null;
    },
   },
  };
</script>
<style>
.dialpadModal.modal-mask {
  justify-content: center !important;
  background-color: rgba(0, 0, 0, 0.9);
  margin-top: 0px;
}
.reminder_icon{
  background-color: hsl(209 13.3% 95.3% / 1)!important;
  color: black!important;
}
  body.dark .reminder_icon {
    background-color: hsl(207 5.6% 31.6% / 1)!important;
    color:white!important;
  }

.dialpadModal >>> .modal-container {
  margin-top: 250px;
}
</style>