<template>
  <div class="w-full"> <!-- Added wrapper div -->
    <div>
      <textarea
        v-model="processedString"
        rows="4"
        readonly
        class="template-input"
      />
      <input
        v-if="template.components[0].format === 'VIDEO' || template.components[0].format === 'IMAGE'"
        type="file"
        class="variable-input"
        :styles="{ marginBottom: 0 }"
        @change="handleFileUpload"
      />
    </div>

    <div v-if="variables" class="template__variables-container">
      <p class="variables-label">
        {{ $t('WHATSAPP_TEMPLATES.PARSER.VARIABLES_LABEL') }}
      </p>
      <div
        v-for="(variable, key) in processedParams"
        :key="key"
        class="template__variable-item"
      >
        <span class="variable-label">
          {{ key }}
        </span>
        <woot-input
          v-model="processedParams[key]"
          type="text"
          class="variable-input"
          :styles="{ marginBottom: 0 }"
        />
        <p v-if="$v.$dirty && $v.$invalid" class="error">
          {{ $t('WHATSAPP_TEMPLATES.PARSER.FORM_ERROR_MESSAGE') }}
        </p>
      </div>
    </div>

    <footer>
      <woot-button variant="smooth" @click="$emit('resetTemplate')">
        {{ $t('WHATSAPP_TEMPLATES.PARSER.GO_BACK_LABEL') }}
      </woot-button>
      <woot-button @click="sendMessage">
        {{ $t('WHATSAPP_TEMPLATES.PARSER.SEND_MESSAGE_LABEL') }}
      </woot-button>
    </footer>
  </div>
</template>

<script>
const allKeysRequired = value => {
  const keys = Object.keys(value);
  return keys.every(key => value[key]);
};

import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import { requiredIf } from 'vuelidate/lib/validators';
export default {
  mixins: [ alertMixin],
  props: {
    template: {
      type: Object,
      default: () => {},
    },
  },
  validations: {
    processedParams: {
      requiredIfKeysPresent: requiredIf('variables'),
      allKeysRequired,
    },
  },
  data() {
    return {
      processedParams: {},
      selectedFile: null, 
    };
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      currentAccountId: 'getCurrentAccountId',
      currentUser: 'getCurrentUser',
    }),
    variables() {
      const variables = this.templateString.match(/{{([^}]+)}}/g);
      return variables;
    },
    templateString() {
      return this.template.components.find(
        component => component.type === 'BODY'
      ).text;
    },
    processedString() {
      return this.templateString.replace(/{{([^}]+)}}/g, (match, variable) => {
        const variableKey = this.processVariable(variable);
        return this.processedParams[variableKey] || `{{${variable}}}`;
      });
    },
  },
  mounted() {
    this.generateVariables();
  },
  methods: {
    async fetchInboxChannelApiKey() {
      try {
        
        // Fetch conversation details
        const response_chat = await axios.get(
          `/api/v1/accounts/${this.currentAccountId}/conversations/${this.currentChat.id}`,
          {
            headers: {
              'api-access-token': this.currentUser.access_token,
              'Content-Type': 'application/json',
            },
          }
        );

        let response_inbox = null;

        // If inbox_id is available, fetch inbox details
        if (response_chat.data.inbox_id) {
          response_inbox = await axios.get(
            `/api/v1/accounts/${this.currentAccountId}/inboxes/${response_chat.data.inbox_id}`,
            {
              headers: {
                'api-access-token': this.currentUser.access_token,
                'Content-Type': 'application/json',
              },
            }
          );
        }

        console.log("Inbox response:", response_inbox);
        return response_inbox;
        
      } catch (error) {
        console.error('Error fetching inbox channel API keys:', error);
        return null;
      }
    },

    async handleFileUpload(event) {
      this.selectedFile = event.target.files[0]; // Get the selected file
      const maxFileSize = 15 * 1024 * 1024; // 15MB
        if (this.selectedFile.size > maxFileSize) {
          this.showAlert('The file size exceeds the 15MB limit. Please upload a smaller file.');
          throw new Error('The file size exceeds the 15MB limit. Please upload a smaller file.');
        }
    },
    async uploadFile() {
      const inbox_response = await this.fetchInboxChannelApiKey();

      if (!this.selectedFile) return null; // No file selected

      const formData = new FormData();
      formData.append('messaging_product', 'whatsapp');
      formData.append('file', this.selectedFile);
      formData.append('type', this.selectedFile.type); // Ensure the correct MIME type

      try {
        const response = await fetch(`https://graph.facebook.com/v20.0/${inbox_response.data.provider_config.phone_number_id}/media`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${inbox_response.data.provider_config.api_key}`,
          },
          body: formData,
        });
        
        const data = await response.json();

        if (response.ok) {
          return data.id; // Return the media ID
        } else {
          console.error('Upload failed:', data);
          return null;
        }
      } catch (error) {
        console.error('Error uploading file:', error);
        return null;
      }
    },
    async sendMessage() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      const mediaId = this.selectedFile ? await this.uploadFile() : null;
      const fileBase64 = this.selectedFile ? await this.convertFileToBase64(this.selectedFile) : null;
      const payload = {
        message: this.processedString,
        templateParams: {
          name: this.template.name,
          category: this.template.category,
          language: this.template.language,
          namespace: this.template.namespace,   
          processed_params: this.processedParams,
          media_id: mediaId ,
          file_name: this.selectedFile?.name || null,
          file_type: this.selectedFile?.type || null, 
          file_format: this.template.components[0].format ? this.template.components[0].format.toLowerCase() : null,
          file_data: fileBase64,
        },
      };
      this.$emit('sendMessage', payload);
    },

    async convertFileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    processVariable(str) {
      return str.replace(/{{|}}/g, '');
    },
    generateVariables() {
      const matchedVariables = this.templateString.match(/{{([^}]+)}}/g);
      if (!matchedVariables) return;

      const variables = matchedVariables.map(i => this.processVariable(i));
      this.processedParams = variables.reduce((acc, variable) => {
        acc[variable] = '';
        return acc;
      }, {});
    },
  },
};
</script>

<style scoped lang="scss">
.template__variables-container {
  @apply p-2.5;
}

.variables-label {
  @apply text-sm font-semibold mb-2.5;
}

.template__variable-item {
  @apply items-center flex mb-2.5;

  .label {
    @apply text-xs;
  }

  .variable-input {
    @apply flex-1 text-sm ml-2.5;
  }

  .variable-label {
    @apply bg-slate-75 dark:bg-slate-700 text-slate-700 dark:text-slate-100 inline-block rounded-md text-xs py-2.5 px-6;
  }
}

footer {
  @apply flex justify-end;

  button {
    @apply ml-2.5;
  }
}
.error {
  @apply bg-red-100 dark:bg-red-100 rounded-md text-red-800 dark:text-red-800 p-2.5 text-center;
}
.template-input {
  @apply bg-slate-25 dark:bg-slate-900 text-slate-700 dark:text-slate-100;
}
</style>
