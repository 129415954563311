import axios from 'axios';

import ApiClient from './ApiClient';

export class WhatsappAPI extends ApiClient {
  constructor() {
    super('templates', { accountScoped: true, apiVersion: 'v3' });
  }

  // eslint-disable-next-line class-methods-use-this
  extractDataFromResponse(response) {
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  marshallData(dataToParse) {
    return { data: dataToParse };
  }

  get = async (headers, accountId) => {
    try {
      const response = await axios.get(
        `/api/v3/whatsapp/templates/whatsapp_inbox?account_id=${accountId}`,
        {
          headers: headers,
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(`Failed to make GET request: ${error.message}`);
    }
  };

  async getInbox({ accountId,inboxId,token }) {
    const headers = {
      api_access_token: token,
    };
    try {
      const data = await this.get(headers, accountId);
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  post = async (accountId, headers, data) => {
    try {
      const url = `/api/v3/whatsapp/templates/create_templates?account_id=${accountId}`;
      const response = await axios.post(url, data, { headers });
      return response;
    } catch (error) {
      throw new Error(`Failed to make POST request: ${error.message}`);
    }
  };

  async createTemplate({ accountId, data }) {
    const headers = {
      'api-access-token' : data.user.access_token,
    }
    try {
      return this.post(accountId, headers, data);
    } catch (error) {
      throw new Error('Failed to create template:', error);
    }
  }

  getTemp = async headers => {
    try {
      const response = await axios.get(
        `/api/v3/whatsapp/templates?account_id=1`,
        {
          headers: headers,
        }
      );
      return this.extractDataFromResponse(response);
    } catch (error) {
      throw new Error(`Failed to make GET request: ${error.message}`);
    }
  };

  getTemplates({ accountId }) {
    const headers = {
      Authorization: `Bearer ${''}`,
      api_access_token: '',
    };
    return this.getTemp(headers, accountId);
  };
  async fetchInboxchannelApi({ inboxId, access_token, accountId }) {
    try {
      const response = await axios.get(
        `/api/v1/accounts/${accountId}/inboxes/${inboxId}`,
        {
          headers: {
            'api-access-token' : access_token,
            'Content-Type': 'application/json',
          },
        }
      );
      return response;
    } catch (err) {
      console.error("Error While Fetching hte Data", err);
    }
  };

  async fetchTemplates({inbox,keys }) {
    try {

      const response = await axios.get(
        `https://graph.facebook.com/v20.0/${inbox.data.provider_config.business_account_id}/message_templates`,
        {
          headers: {
            Authorization: `Bearer ${keys.whatsapp_secret_key}`,
            'Content-Type': 'application/json',
          },
        }
      );
      return response;
    } catch (err) {
      console.error("Error While Fetching hte Data", err);
    }
  }

  async ViewTemplates({inboxid,keys }) {
    try {      
      const response = await axios.get(
        `https://graph.facebook.com/v20.0/${inboxid}`,
        {
          headers: {
            Authorization: `Bearer ${keys.whatsapp_secret_key}`, // Replace with your actual access token
            'Content-Type': 'application/json',
          },
        }
      );
      return response;
    } catch (err) {
      console.error("Error While Fetching the Data", err);
    }
  }
  
  async deleteTemplates({name,keys,inbox}){
    try{
      const response = await axios.delete(
        `https://graph.facebook.com/v20.0/${inbox.data.provider_config.business_account_id}/message_templates?name=${name}`,
        {
          headers: {
            Authorization: `Bearer ${keys.whatsapp_secret_key}`,
            'Content-Type': 'application/json',
          },
        }
      );
      return response;
    }catch(error){
      console.error("Error while delete the TEmplate",error);
    }
  }
  
  async submitEditData({keys,inboxId,dataToSend}){
    try{
      const headers = {
        Authorization: `Bearer ${keys.whatsapp_secret_key}`,
        'Content-Type': 'application/json',
      };
  
      // Send the POST request to the Facebook Graph API
      const response = await axios.post(
        `https://graph.facebook.com/v20.0/${inboxId}`,
        dataToSend,
        { headers }
      );
      return response;
    } catch (err) {
      if (err.response) {
        console.error('Error response data:', err.response.data);
        return { success: false, error: err.response.data };
      } else if (err.request) {
        console.error('Error request data:', err.request);
        return { success: false, error: 'No response received from the server' };
      } else {
        console.error('Error message:', err.message);
        return { success: false, error: err.message };
      }
    }
  }
}
export default new WhatsappAPI();
