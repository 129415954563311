<template>
  <div class="flex-1 overflow-auto p-4 flex justify-between flex-col">
    <a :href="inboxTemplateUrl" class="button success button--fixed-top">
      <svg
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2Zm0 1.5a8.5 8.5 0 1 0 0 17 8.5 8.5 0 0 0 0-17ZM12 7a.75.75 0 0 1 .75.75v3.5h3.5a.75.75 0 0 1 0 1.5h-3.5v3.5a.75.75 0 0 1-1.5 0v-3.5h-3.5a.75.75 0 0 1 0-1.5h3.5v-3.5A.75.75 0 0 1 12 7Z"
          fill="currentColor"
        ></path>
      </svg>
      <span class="button__content"> Add Whatsapp Templates </span>
    </a>
    <!-- <router-link
      :to="inboxTemplateUrl"
      class="button success button--fixed-top"
    >
      <fluent-icon icon="add-circle" />
      <span class="button__content"> Add Whatsapp Templates </span>
    </router-link> -->
    <!-- List Audit Logs -->
    <div class="divone">
      <div class="divtwo">
        <div class="sync-button">
          <button class="button success" @click="syncData">Sync Data</button>
        </div>
        <table v-if="!uiFlags.isCreating && templates.length" class="whatsapp">
          <thead>
            <tr>
              <th>NAME</th>
              <th>MESSAGE TYPE</th>
              <th>LANGUAGE</th>
              <th>STATUS</th>
              <th>VIEW</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="template in templates" :key="template.id">
              <td>{{ template.name }}</td>
              <td>{{ template.category }}</td>
              <td>{{ template.language }}</td>
              <td>{{ template.status }}</td>

              <td>
                <woot-button
                  v-if="template.status == 'APPROVED'"
                  v-tooltip.top-end="'View'"
                  size="tiny"
                  variant="smooth"
                  icon="edit"
                  @click="viewCategory(template.id)"
                />

                <!--<woot-button
                  v-tooltip.top-end="'Edit'"
                  size="tiny"
                  variant="smooth"
                  icon="edit"
                  color-scheme="secondary"
                  @click="editCategory(template.id)"
                /> -->

                <woot-button
                  v-tooltip.top-end="'delete'"
                  size="tiny"
                  variant="smooth"
                  icon="delete"
                  color-scheme="alert"
                  @click="deleteTemplate(template.name)"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <p class="no-data text-center" v-if="!uiFlags.isCreating && templates.length === 0">
          No Data to display.
        </p>
        <div v-if="uiFlags.isCreating" class="notifications--loader">
          <spinner size="large" color-scheme="primary" />
          <span>{{ $t('NOTIFICATIONS_PAGE.LIST.WHATSAPP_MESSAGE') }}</span>
        </div>
        <div v-if="isLoading" class="template--loader">
          <spinner size="large" color-scheme="primary" />
          <span class="text-white">{{ dynamicText }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import accountMixin from '../../../../mixins/account';
import alertMixin from 'shared/mixins/alertMixin';
import adminMixin from '../../../../mixins/isAdmin';
import Spinner from 'shared/components/Spinner.vue';
import fetchSecretMixin from 'shared/mixins/fetchSecret';
import { ca } from 'date-fns/locale';

export default {
  components: { Spinner },
  mixins: [accountMixin, alertMixin, adminMixin, fetchSecretMixin],
  data() {
    return {
      loading: false,
      showSettings: false,
      showDeletePopup: false,
      showTable: false,
      templates: [],
      phoneNumber: '',
      showEditModal: false,
      showViewModal: false,
      closeEditModal: true,
      closeViewModal: true,
      selectedTemplateId: null,
      deletebutton: false,
      dynamicText: '',
    };
  },
  // props: {
  //   inbox: {
  //     type: Object,
  //     required: true,
  //   },
  // },
  computed: {
    ...mapGetters({
      templateLists: 'whatsappTemplates/getTemplates',
      currentAccountId: 'getCurrentAccountId',
      currentUser: 'getCurrentUser',
      uiFlags: 'whatsappTemplates/getUIFlags',
    }),
    inboxTemplateUrl() {
      return `/app/accounts/${this.$route.params.accountId}/settings/inboxes/${this.$route.params.inboxId}/whatsapp_template/add_contents`;
    },
    isLoading() {
      return this.uiFlags.isDeleting || this.uiFlags.isFetching;
    },
  },
  mounted() {
    this.syncData();
  },
  methods: {
    
    async fetchInboxChannelApiKey() {
      try {
        const inboxId = this.$route.params.inboxId;
        const response = await axios.get(
          `/api/v1/accounts/${this.currentAccountId}/inboxes/${inboxId}`,
          {
            headers: {
              'api-access-token': this.currentUser.access_token,
              'Content-Type': 'application/json',
            },
          }
        );
        const apiKey = response.data.provider_config.api_key;
        this.phoneNumber = response.data.phone_number;
        return response;
      } catch (error) {
        console.error('Error fetching inbox channel API keys:', error);
        return null;
      }
    },
    async fetchTemplates() {
      try {
        this.loading = true;
        const inbox = await this.fetchInboxChannelApiKey();
        const keys = await this.getKeys()
        const response = await axios.get(
          `https://graph.facebook.com/v20.0/${inbox.data.provider_config.business_account_id}/message_templates`,
          {
            headers: {
              Authorization: `Bearer ${keys.whatsapp_secret_key}`,
              'Content-Type': 'application/json',
            },
          }
        );
        if (response?.status === 200) {
          try {
            await this.syncWhatsappTemplate();
          } catch (syncError) {
            console.error('Error syncing WhatsApp template:');
          }
        }
        this.templates = response && response?.data?.data?.map(template => ({
          id: template.id,
          name: template.name,
          language: template.language,
          status: template.status,
          category: template.category,
        }));
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.templates = [];
        console.error('Error fetching templates:', error);
      }
    },
    async syncData() {
      const inboxId = this.inboxId();
      const access_token = this.currentUser.access_token;
      const accountId = this.currentAccountId;
      const inbox = await this.$store.dispatch(
        'whatsappTemplates/fetchInboxChannelApiKey',
        {
          inboxId: inboxId,
          access_token: access_token,
          accountId: accountId,
        }
      );
      if (!inbox) {
        console.log('Inbox Not Geeting');
        return;
      }
      const keys = await this.getKeys();
      const response = await this.$store.dispatch(
        'whatsappTemplates/fetchTemplates',
        {
          inbox: inbox,
          keys: keys,
        }
      );
      if (response?.status === 200) {
        try {
          await this.syncWhatsappTemplate();
        } catch (syncError) {
          console.error('Error syncing WhatsApp template:');
        }
      }

      this.templates = response.data.data.map(template => ({
        id: template.id,
        name: template.name,
        language: template.language,
        status: template.status,
        category: template.category,
      }));
    },

    inboxId() {
      return this.$route.params.inboxId;
    },

    async viewCategory(id) {
      try {
        this.dynamicText = 'Viewing Templates....';
        const keys = await this.getKeys();

        await this.$store.dispatch('whatsappTemplates/ViewForm', {
          id: id,
          keys: keys,
        });
        this.$router.push(this.editTemplatesUrl(id));
      } catch (err) {
        console.log('Error While Viewing The data', err);
      } finally {
        this.dynamicText = '';
      }
    },

    editTemplatesUrl(templateId) {
      return `/app/accounts/${this.$route.params.accountId}/settings/inboxes/${this.$route.params.inboxId}/whatsapp_template/edit/${templateId}`;
    },
    // async deleteTemplate(name){
    //   try{
    //     this.deletebutton=true;
    //     const inbox = await this.fetchInboxChannelApiKey();
    //     const keys = await this.getKeys()
    //     const response = await axios.delete(
    //       `https://graph.facebook.com/v20.0/${inbox.data.provider_config.business_account_id}/message_templates?name=${name}`,
    //       {
    //         headers: {
    //           Authorization: `Bearer ${keys.whatsapp_secret_key}`,
    //           'Content-Type': 'application/json',
    //         },
    //       }
    //     );
    //     if (response.status === 200) {
    //       this.showAlert(
    //       'template deleted successfully'
    //       )
    //     }else{
    //       this.showAlert(
    //       'template not deleted'
    //     )
    //     }
    //     this.fetchTemplates();
    //   }catch(error){
    //     console.error("Error while delete the TEmplate",error);
    //   }
    //   finally{
    //     this.deletebutton=false;
    //   }
    // },
    async deleteTemplate(name) {
      try {
        this.dynamicText = 'Deleting Templates....';
        const inboxId = this.inboxId();
        const access_token = this.currentUser.access_token;
        const accountId = this.currentAccountId;
        const inbox = await this.$store.dispatch(
          'whatsappTemplates/fetchInboxChannelApiKey',
          {
            inboxId: inboxId,
            access_token: access_token,
            accountId: accountId,
          }
        );
        // this.deletebutton=true;
        // const inbox = await this.fetchInboxChannelApiKey();
        const keys = await this.getKeys();
        // const response = await axios.delete(
        //   `https://graph.facebook.com/v20.0/${inbox.data.provider_config.business_account_id}/message_templates?name=${name}`,
        //   {
        //     headers: {
        //       Authorization: `Bearer ${keys.whatsapp_secret_key}`,
        //       'Content-Type': 'application/json',
        //     },
        //   }
        // );
        const response = await this.$store.dispatch(
          'whatsappTemplates/deleteTemplate',
          {
            inbox: inbox,
            name: name,
            keys: keys,
          }
        );
        if (response.status === 200) {
          this.showAlert('template deleted successfully');
          this.syncData();
        } else {
          this.showAlert('template not deleted');
        }
        this.fetchTemplates();
      } catch (error) {
        console.error('Error while delete the TEmplate', error);
        // this.showAlert('template not deleted');`
      } finally {
        // this.deletebutton=false;
      }
    },
    async getSecretKeys() {
      const res = await this.getKeys()
    },
    async syncWhatsappTemplate() {
      try {
        const inboxId = this.$route.params.inboxId;
        const accountIdId = this.$route.params.accountId;
        const response = await axios.get(
          `/api/v1/accounts/${accountIdId}/inboxes/${inboxId}/sync_templates`,
          {
            headers: {
              api_access_token: this.currentUser.access_token,
              'Content-Type': 'application/json',
            },
          }
        );
      } catch (error) {
        console.error('Error syncing WhatsApp template');
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.divone {
  @apply p-3 bg-white dark:bg-slate-900;
}
.whatsapp {
  border: 2px solid #e8e8e8;
}
.sync-button {
  display: flex;
  justify-content: end;
  margin-bottom: 10px;
}
.notifications--loader {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
.template--loader {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  pointer-events: none;
  cursor: not-allowed;
}
tbody tr {
  border: 1px solid #e8e8e8;
  background-color: whitesmoke;
}
thead tr {
  background-color: #e9e9e9;
}
table {
  @apply border-spacing-0 text-sm;

  thead {
    th {
      @apply font-semibold tracking-[1px] text-left uppercase text-slate-900 dark:text-slate-200 dark:bg-slate-900;
    }
  }

  tbody {
    tr {
      @apply border-b border-slate-900/30 dark:border-slate-200/30;
    }

    td {
      @apply p-2.5 text-slate-700 dark:text-slate-200 dark:bg-slate-900;
    }
  }
}
</style>
